import React from "react"

import OmniLogo from '/src/images/omni.png'
import AdtLogo from '/src/images/adt.png'
import WendysLogo from '/src/images/wendys.png'
import TrpLogo from '/src/images/troweprice.png'
import KiiLogo from '/src/images/kii.png'
import PrimelendingLogo from '/src/images/primelending.png'
import BBLogo from '/src/images/bb.png'
import PoptvLogo from '/src/images/poptv.png'
import WaterpikLogo from '/src/images/waterpik.png'



export default function Logos() {
  return (
    <div className="bg-gray-50 border-b-2">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
          Some of the great companies that have trusted us. 
        </p>
        <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img
              className="max-h-12"
              src={OmniLogo}
              alt="Omni Hotels and Resorts Logo"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img className="max-h-12" src={AdtLogo} alt="ADT Logo" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img className="max-h-12" src={BBLogo} alt="Brown & Brown Logo" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img className="max-h-12" src={PoptvLogo} alt="Pop Tv Logo" />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img
              className="max-h-12"
              src={TrpLogo}
              alt="T Rowe Price Logo"
            />
          </div>
          <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
            <img
              className="max-h-12"
              src={WaterpikLogo}
              alt="Waterpik Logo"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

  