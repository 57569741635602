import React, { useState } from "react"
import { Script } from "gatsby"

export default function PipedriveForm() {
  return (
    <div id= "demo-request" className="relative bg-white border-t-2">
    <div className="absolute inset-0">
      <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
    </div>
    <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
      <div className="bg-gray-50 px-6 py-16 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
        <div className="mx-auto max-w-lg">
          <p className="mt-3 text-lg leading-6 text-gray-500">
            We would love the opportunity to walk you through getregistered.
            A member of the team can arrange a live demo for you.
          </p>
          <dl className="mt-8 text-base text-gray-500">
            <div>
              <dt className="sr-only">Postal address</dt>
              <dd>
                <p><b>Headquarters</b></p>
                <p>28 W. Central Blvd. Suite 210</p>
                <p>Orlando, FL 32801, USA</p>
              </dd>
            </div>
            <div className="mt-6">
              <dt className="sr-only">Email</dt>
              <dd className="flex">
                <svg
                  className="h-6 w-6 flex-shrink-0 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
                <span className="ml-3">support@getregistered.com</span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="bg-white px-6 py-16 lg:col-span-3 lg:px-8 lg:py-24 xl:pl-12">
        <div className="mx-auto max-w-lg lg:max-w-none">
          <div className="pipedriveWebForms" data-pd-webforms="https://webforms.pipedrive.com/f/6WbYwFRbvyadtM7hDnJcCyW1ZFeOk2yUsoCg6UPjZGVDySjP1UeVzGDhiWlSlA2p6b">
            <Script src="https://webforms.pipedrive.com/f/loader"/>
          </div>
        </div>
      </div>
    </div>
  </div>  
  )
}

