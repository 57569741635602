import * as React from "react"
import Metadata from "../components/metadata"
import Layout from "../components/layout"
import FeatureFlow from "../components/feature-flow"
import FeatureGrid from "../components/feature-grid-alt"
import Testimonial from "../components/testimonial"
import PipedriveForm from "../components/pipedrive-form"
import Logos from "../components/logos"
import Footer from "../components/footer"
import Gauges from "../components/gauges"

export default function Index() {
  return (
    <Layout>
      <Logos />
      <Metadata />
      <FeatureFlow />
      <Testimonial />
      <FeatureGrid />
      <PipedriveForm />
      <Footer />
      <Gauges />
    </Layout>
  );
}