import React, { useState } from "react"
import { Script } from "gatsby"

export default function Gauges() {
  return (
    <Script>
      {
        `var _gauges = _gauges || [];
        (function() {
          var t   = document.createElement('script');
          t.type  = 'text/javascript';
          t.async = true;
          t.id    = 'gauges-tracker';
          t.setAttribute('data-site-id', '61afcc9fb3bff6658ab1159b');
          t.setAttribute('data-track-path', 'https://track.gaug.es/track.gif');
          t.src = 'https://d2fuc4clr7gvcn.cloudfront.net/track.js';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(t, s);
        })();`
      }
    </Script>
  )
}
