import React from "react"
import { CheckIcon } from '@heroicons/react/outline'
import { gridFeatures, slots } from "../content"

export default function FeatureGrid() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="mx-auto text-center">
          <h2 className="text-base font-semibold tracking-wider text-gray-500 uppercase">Advanced Features</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
            {slots.gridFeatures.title}
          </p>
          <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
            {slots.gridFeatures.tagline}
          </p>
        </div>
        <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
          {gridFeatures.map((feature, i) => (
            <div key={i} className="relative">
              <dt>
                <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
        <div className="mx-auto text-center mt-12">
          <h3 className="text-base font-semibold tracking-wider text-gray-900">
            {slots.gridFeatures.takeaway}
          </h3>
        </div>
      </div>
    </div>
  )
}

